import * as React from "react";
import "../../i18n";
import { Link } from "gatsby";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Logo1 from "../../images/logo1.png";
import Logo2 from "../../images/logo2.png";
import Logo3 from "../../images/logo3.png";
import Logo4 from "../../images/logo4.png";
import Logo5 from "../../images/logo5.png";
import Logo6 from "../../images/logo6.png";
import Logo7 from "../../images/logo7.png";
import Logo8 from "../../images/logo8.png";
import "./../landing.css";
import ScrollContainer from "react-indiana-drag-scroll";
import useMediaQuery from "../../hooks/useMediaQuery";
import AllLandingWorks from "../../components/LandingWorkItem/AllLandingWorks";
import { useEffect, useRef, useState } from "react";
import Arrow from "../../assets/arrows/arrowWhite.png";
import ArrowPurple from "../../assets/arrows/arrowPurpleShort.svg";
import ArrowGreen from "../../assets/arrows/ArrowProper.png";
import  { useTranslation } from "../../i18n";
import i18n from "i18next"
import { useTheme } from "../../components/ThemeProvider/ThemeProvider";
import { useVisibility } from "../../hooks/useVisibility";
import BumperVideo from "../../assets/bumper.webm";
import { RiContactsBookLine } from "react-icons/ri";
import { Helmet } from "react-helmet";

const IndexPage = () => {

	useEffect(() => {
		i18n.changeLanguage("jp")
		  },
	[]);

	const { t } = useTranslation();
	const { colorMode } = useTheme();

	const isDesktop = useMediaQuery("(min-width: 1200px)");

	const [navbarForcedTheme, setNavbarForcedTheme] = useState<
		"dark" | undefined
	>("dark");
	const [forceNavbarColor, darkZoneRef] = useVisibility<HTMLDivElement>(true);

	useEffect(() => {
		setNavbarForcedTheme(forceNavbarColor ? "dark" : undefined);
	}, [forceNavbarColor]);

	const [arrowState, setArrowState] = useState<string>(
		"mt-[10rem] opacity-0 invisible"
	);

	const handleArrowState = () => {
		if (arrowState === "mt-[10rem] opacity-0 invisible") {
			setArrowState("duration-200 opacity-100 visible");
		} else {
			setArrowState("mt-[10rem] opacity-0 invisible");
		}
	};

	const [arrowColor1, setArrowColor1] = useState<boolean>(false);

	const handleArrowColor1 = () => {
		setArrowColor1((old) => !old);
	};

	const [arrowColor2, setArrowColor2] = useState<boolean>(false);

	const handleArrowColor2 = () => {
		setArrowColor2((old) => !old);
	};

	// Smooth scrolling
	const ref = useRef<HTMLDivElement>(null);

	const handleClick = () => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	};

	const videoRef = useRef<HTMLVideoElement>(null);
	useEffect(() => {
		if (videoRef?.current) {
			videoRef.current.play().catch(() => { });
		}
	}, [videoRef]);

	return (
		<div className="bg-tofuDarkGrey">
			<Navbar forceColorMode={navbarForcedTheme} />
			<Helmet>
				<title>TOFU Media</title>
				<meta name="description" content={"TOFU Mediaは、東京に拠点を置く、国際色豊かなクリエイティブプロダクション・映像制作会社です。国内外を問わず、映像、CM、デジタルコンテンツの制作などを承っております。"} />
				<meta name="keywords" content={"TOFU Media クリエイティブプロダクション コンテンツ制作 デジタルコンテンツ 制作 コンテンツ制作 東京 映像制作 会社"} />
			</Helmet>
			<div className="bg-tofuLightGrey flex flex-col items-center align-center w-[100%]">
				<div className="relative">
					<div className="h-[100vh] lg:h-[115vh] w-full lg:w-screen bg-[#31313180] absolute"></div>
					<video
						autoPlay
						ref={videoRef}
						muted
						loop
						controls={false}
						poster={require("../../assets/video-poster.png").default}
						className="object-cover overflow-hidden h-[100vh] lg:h-[115vh] w-auto lg:w-screen"
						preload="none"
						playsInline
					>
						<source src="https://res.cloudinary.com/tofu-media/video/upload/v1676880074/MainVideo_wpugvl-q_auto_low_kujljs.mp4" type="video/mp4" />
					</video>
					<div className="h-auto flex-col ml-[3%] lg:ml-[10%] w-[60%] bottom-[6rem] md:bottom-[12rem] lg:bottom-[16rem] object-scale-down  absolute">
						<div className="relative w-auto font-syne text-white text-[7.5vw] md:text-4xl xl:text-6xl 2xl:text-[67px] 3xl:text-6xl font-extrabold duration-300 transition hover:text-tofuGreen leading-none">
							{t("who_are_we1")} <br />
							{t("who_are_we2")} <br />
							{t("who_are_we3")} <br />
							{t("who_are_we4")} <br />
							{t("who_are_we5")}
						</div>
						<div
							ref={darkZoneRef}
							className=" border-t-2  inline-block relative w-[70vw] lg:w-auto mt-4 pt-4  font-syne font-thin text-white text-md  xl:text-lg  leading-tight "
						>
							{t("content_solutions")}
						</div>
					</div>
					<div className="w-[18vw] md:w-[25vw] h-16 md:h-32 bg-tofuLightGrey absolute mt-[-4rem] md:mt-[-8rem]"></div>
					<div className="w-[18vw] md:w-[25vw] h-16 md:h-32 bg-tofuLightGrey absolute mt-[-4rem] md:mt-[-8rem] right-0 "></div>
				</div>

				{isDesktop ? (
					<div className="flex flex-row items-center w-full justify-between">
						<div className="z-20 text-center w-[100vw] text-tofuLightWhite text-3xl md:text-6xl mt-20 mb-4  bottom-[21vh] font-syne font-extrabold hover:text-tofuGreen ">
							{t("works")}
						</div>
						<img
							src={Arrow}
							className={`z-10 right-0 absolute mr-[2vw] w-[12vw] pt-14 ${colorMode === "light" && "invert"
								} ${arrowState}`}
						/>
					</div>
				) : (
					<div className="flex flex-col items-center w-full justify-between">
						<div className="z-20 text-center w-[100vw] text-tofuLightWhite text-3xl md:text-7xl mt-20 mb-4  bottom-[21vh] font-syne font-extrabold hover:text-tofuGreen ">
							WORKS
						</div>
						<img
							src={ArrowPurple}
							className="mr-[2vw] mb-10 w-[20vw] ${arrowState}"
						/>
					</div>
				)}

				{isDesktop ? (
					<div
						onMouseEnter={handleArrowState}
						onMouseLeave={handleArrowState}
						className="w-full align-center"
					>
						<ScrollContainer
							vertical={false}
							activationDistance={50}
							hideScrollbars={false}
							className="flex flex-row min-h-[100vh]"
						>
							<div className="w-8 h-32 mr-8"></div>
							<AllLandingWorks />
							<div className="ml-24 flex flex-col justify-center items-center w-[60vw]">
								<div className="justify-center items-center flex flex-row">
									<p className="mx-10 w-[40rem] text-3xl text-tofuLightWhite text-center font-syne font-bold">
										{t("see_works1")}
									</p>
									<Link
										hrefLang="jp"
										onMouseEnter={handleArrowColor1}
										onMouseLeave={handleArrowColor1}
										to="/portfolio"
									>
										{arrowColor1 ? (
											<img className="w-64" src={ArrowGreen} alt="An arrow" />
										) : (
											<img
												className={`w-64 ${colorMode === "light" && "invert"}`}
												src={Arrow}
												alt="An arrow"
											/>
										)}
									</Link>
								</div>
								<div className="flex flex-row">
									<div
										onMouseEnter={handleArrowColor2}
										onMouseLeave={handleArrowColor2}
										onClick={handleClick}
										className="flex flex-col justify-center items-center mr-48"
									>
										<p className="text-3xl text-tofuLightWhite text-center font-syne font-bold">
											{t("see_works2")}
										</p>
										{arrowColor2 ? (
											<img
												className="rotate-90 w-48 mt-24 cursor-pointer"
												src={ArrowGreen}
												alt="An arrow"
											/>
										) : (
											<img
												className={`rotate-90 w-48 mt-24 cursor-pointer ${colorMode === "light" && "invert"
													}`}
												src={Arrow}
												alt="An arrow"
											/>
										)}
									</div>
								</div>
							</div>
							<div className="w-8 h-32 mr-[28vw]"></div>
						</ScrollContainer>
					</div>
				) : (
					<div className="overflow-hidden w-[100%] h-[auto] align-center overflow-x-scroll ">
						<ScrollContainer
							vertical={false}
							activationDistance={50}
							hideScrollbars={false}
							className="flex flex-row"
						>
							<div className="ml-5"></div>
							<AllLandingWorks mobile />
							<div className="ml-24 flex flex-col justify-center items-center w-[60vw]">
								<div className="justify-center items-center flex flex-row">
									<p className="ml-10 mr-4 w-[14rem] text-lg text-tofuLightWhite font-syne text-center leading-loose font-bold">
										SEE ALL WORKS <br /> OR SCROLL ON
									</p>
									<Link hrefLang="jp" to="/portfolio/samsung-galaxy-z-flip-web-commercial">
										<img
											className="mt-[-1.8rem] pr-12 h-[1.5rem]"
											src={ArrowPurple}
											alt="An arrow"
										/>
									</Link>
								</div>
								<img
									className="rotate-90 w-20 mr-[6rem] mt-12"
									src={ArrowPurple}
									alt="An arrow"
								/>
							</div>
						</ScrollContainer>
					</div>
				)}

				<div
					id="clients"
					className="text-tofuLightWhite  text-3xl md:text-6xl mt-16 lg:mt-64  bottom-[21vh] font-syne font-extrabold hover:text-tofuGreen "
					ref={ref}
				>
					{t("clients")}
				</div>
				<div className="w-[65%] lg:w-[90%] lg:mb-32 text-tofuLightWhite text-lg 2xl:text-2xl 3xl:text-3xl leading-tight font-syne font-light text-center mt-8 mb-4 bottom-[21vh] flex flex-col items-center  ">
					<p>{t("clients_desc1")}</p>
					<p>{t("clients_desc2")}</p>
				</div>
				{isDesktop ? (
					<div className="flex flex-wrap justify-between flex-row mx-[4%] mb-64">
						<img
							src={Logo2}
							className={`${colorMode === "dark" && "invert"
								} h-[2.8vw] hidden lg:flex`}
						/>
						<img
							src={Logo8}
							className={`${colorMode === "dark" && "invert"
								} scale-[70%] h-[2.8vw]`}
						/>
						<img
							src={Logo7}
							className={`${colorMode === "dark" && "invert"
								} scale-[150%] mx-12 h-[2.8vw]`}
						/>
						<img
							src={Logo3}
							className={`${colorMode === "dark" && "invert"
								} h-[2.8vw] hidden scale-[60%] lg:flex`}
						/>
						<img
							src={Logo6}
							className={`${colorMode === "dark" && "invert"
								} scale-[40%] -mx-12 h-[2.8vw]`}
						/>
						<img
							src={Logo5}
							className={`${colorMode === "dark" && "invert"
								} scale-[60%] h-[2.8vw]`}
						/>
						<img
							src={Logo1}
							className={`${colorMode === "dark" && "invert"
								} h-[2.8vw] scale-[60%] hidden lg:flex`}
						/>
						<img
							src={Logo4}
							className={`${colorMode === "dark" && "invert"
								} h-[2.8vw] hidden lg:flex`}
						/>
					</div>
				) : (
					<ScrollContainer
						hideScrollbars={false}
						className="h-24 mt-12 flex flex-row items-center"
					>
						<img
							src={Logo2}
							className={`${colorMode === "dark" && "invert"
								} lg:flex h-[2rem] mx-6`}
						/>
						<img
							src={Logo8}
							className={`${colorMode === "dark" && "invert"} h-[1.4rem] mx-6`}
						/>
						<img
							src={Logo7}
							className={`${colorMode === "dark" && "invert"} h-[3rem] mx-6`}
						/>
						<img
							src={Logo3}
							className={`${colorMode === "dark" && "invert"
								} lg:flex h-[1.6rem] mx-6`}
						/>
						<img
							src={Logo6}
							className={`${colorMode === "dark" && "invert"} h-[1rem] mx-6`}
						/>
						<img
							src={Logo5}
							className={`${colorMode === "dark" && "invert"} h-[1.2rem] mx-6`}
						/>
						<img
							src={Logo1}
							className={`${colorMode === "dark" && "invert"
								}  lg:flex h-[1.2rem] mx-6`}
						/>
						<img
							src={Logo4}
							className={`${colorMode === "dark" && "invert"
								}  lg:flex h-[2rem] mx-6`}
						/>
					</ScrollContainer>
				)}
			</div>
			<Footer />
		</div>
	);
};
export default IndexPage;
